import { HttpClient } from '@angular/common/http';
import { Subject, Observable, AsyncSubject } from 'rxjs';
import { share } from 'rxjs/operators';

import { Constants } from './constants';
import { Injectable, inject } from '@angular/core';
import { IdentityUser } from '@domain/models/identity/identity-user';
import { MenuHeader } from '@domain/models/identity/menu-header';
import { IdentityRepository } from '@domain/models/identity/gateway/identity-repository';

@Injectable({
  providedIn: 'root'
})
export class IdentityServiceImpl extends IdentityRepository {
  /** representa el usuario logueado en el sistema */
  private user?: IdentityUser = undefined;

  /** Subject para notificar la informacion del usuario  */
  private authState = new Subject<IdentityUser>();

  /**
   *  Almacena una referencia hacia el observable que obtiene la información del usuario y lo marca de tipo share para que
   *  no se haga una misma peticion multiples veces
   */
  private observerLoginInfo?: Observable<any> = undefined;

  /**
   * Constructor de la clase
   *
   * @param HttpClient servicio para hacer peticiones http
   */
  private readonly http = inject(HttpClient);

  /**
   * Esta función se encarga de enviar por el authState el usuario, ya sea que lo recupere del sessionStorage o del servicio
   * expuesto en el backend
   */
  refreshUserInfo(): void {
    this.observerLoginInfo = this.http
      .get(Constants.contextoServicios + Constants.securityUserInfo)
      .pipe(
        share({
          connector: () => new AsyncSubject(),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false
        })
      );

    this.observerLoginInfo.subscribe((user) => {
      this.user = user;
      if (this.user) {
        const menuUser = JSON.parse(user.menu) as MenuHeader[];
        this.user.menu = menuUser.find(
          (menu) => menu.name === Constants.nameMenuRocket
        );
        this.authState.next(user);
      }
    });
  }

  /**
   * Devuelve un Observable en el cual se notificará el usuario en la sesión.
   *
   * @returns devuelve un stream donde se puede obtener la información del usuario logueado
   */
  getUserInfo(): Observable<IdentityUser> {
    if (!this.observerLoginInfo) {
      this.refreshUserInfo();
    }

    return this.authState.asObservable();
  }

  /**
   * Devuelve el menu asociado a el usuario
   *
   * @returns devuelve el menu del usuario
   */
  getUserMenu(): Promise<MenuHeader> {
    return new Promise((resolver) => {
      if (this.user != null) {
        resolver(this.user.menu);
      } else {
        this.getUserInfo().subscribe((user) => {
          resolver(user.menu);
        });
      }
    });
  }

  /**
   * Con esta funcion se puede obtener el valor LoggedInYet o el formulario de login enviado por el sistema de seguridad
   *
   * @returns devuelve el html que representa el formulario de login
   */
  getLoginForm(): Observable<string> {
    const options = { responseType: 'text' as const };
    return this.http.post(
      Constants.contextoServicios + Constants.securityCheckStatus,
      null,
      options
    );
  }

  /**
   * Esta funcion comprueba si un usuario esta logueado en el sistema
   *
   * @returns indica si el usuario esta logueado en el sistema o no
   */
  isLoggedIn(): boolean {
    return this.user != null;
  }

  /**
   * Con esta funcion se puede comprobar si el usuario en la sesión tiene permiso hacia un recurso especifico
   *
   * @param resource url del recurso
   * @returns indica si un usuario tiene acceso a un recurso específico
   */
  isAuthorized(resource: string): Observable<boolean> {
    const body = {
      method: 'GET',
      path: resource
    };
    return this.http.post<boolean>(
      Constants.contextoServicios + Constants.securityUserAccess,
      body
    );
  }

  /**
   * Esta funcion realiza el logout parcial del sistema, no cierra la sesión.
   */
  // partialLogout(): void {
  //   window.location.href = '/logout';
  // }

  /**
   * Esta funcion invoca el logout completo del sistema, aqui si se cierra la sesión y el usuario debería ingresar sus
   * credenciales si desea volver a ingresar.
   */
  fullLogout(fullUrlLogout: string): void {
    // es necesario limpiar el sessionStorage
    this.clearObserverForLogin();
    window.location.href = fullUrlLogout || '';
  }

  /**
   * Esta función se encarga de eliminar la referencia del Observable que devuelve la información del usuario logueado
   * de esta forma se puede hacer refresh de dicha información solicitandola al backend
   */
  clearObserverForLogin(): void {
    this.observerLoginInfo = undefined;
    this.user = undefined;
  }
}
